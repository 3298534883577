import React, {useState} from 'react'
import { PageTemplate } from '../templates/page'
import { destroy } from 'cart-localstorage'
import { navigate } from 'gatsby'
import GetQueryParameter from '../scripts/get-query-parameter'

const pageProps = {
  pageIdentifier: "thanks",
  pageClass: "hide-cart",
  showCTA: false,
  footerCTAText: "Need Help? Contact Us",
  footerCTALink: "/contact/",
}

async function sendEmails(template, cart, metadata, pathname, orderID) {
  await fetch('/.netlify/functions/send-mail', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      'template': template,
      'cart': cart,
      'metadata': metadata
    }),
  }).then(() => navigate( pathname + '?success=true&order_id=' + orderID ) )
}

const ThanksPage = ({location}) => {
  let orderID = GetQueryParameter('order_id', location.href)
  let successParam = GetQueryParameter('success', location.href)
  let cartParam = GetQueryParameter('cart', location.href)

  const [emailsSent, setEmailsSent] = useState(false)

  // It's an order Thank You page
  if ( orderID && successParam ) {
    // Delete products from cart
    destroy()

    return (
      <PageTemplate 
        title="Order Received!"
        subtitle={"Your Order reference is <strong>" + orderID + "</strong> and we have sent you a receipt by e-mail.<br />Your order will be ready for you on the date you chose.<br />If you have any questions, please contact us."}
        pageIdentifier={pageProps.pageIdentifier}
        pageClass={pageProps.pageClass}
        showCTA={pageProps.showCTA}
        footerCTAText={pageProps.footerCTAText}
        footerCTALink={pageProps.footerCTALink}
      />
    )
  } else if (orderID && cartParam) {
    if ( !emailsSent ) {
      // Prepare and send emails
      let cart = []
      cartParam = cartParam.split('||')

      Object.keys(cartParam).map(item => {
        let itemSplit = cartParam[item].split('|')

        cart[item] = {
          "name": itemSplit[0],
          "quantity": itemSplit[1],
          "price": itemSplit[2],
        }

        return false
      })
      
      sendEmails('orderSuccessToCustomer', cart, location.search, location.pathname, orderID)
      setEmailsSent(true)
    }

    return (
      <PageTemplate 
        title="Please wait..."
        subtitle="We are processing your payment and will redirect you shortly.<br /> Please <strong>do not</strong> navigate away from this page..."
        pageIdentifier={pageProps.pageIdentifier}
        pageClass={pageProps.pageClass}
        showCTA={pageProps.showCTA}
        footerCTAText={pageProps.footerCTAText}
        footerCTALink={pageProps.footerCTALink}
      />
    )
  }
  // It's an enquiry Thank You page
  else {
    return (
      <PageTemplate 
        title="Thank You!"
        subtitle="We received your enquiry and will get back to you as soon as possible."
        pageIdentifier={pageProps.pageIdentifier}
        pageClass={pageProps.pageClass}
        showCTA={pageProps.showCTA}
        footerCTAText={pageProps.footerCTAText}
        footerCTALink={pageProps.footerCTALink}
      />
    )
  }
  
  
}

export default ThanksPage